import React from 'react'
import './DownArrow.scss'
import scrollAnimation from '../../HelperFunctions/ScrollAnimation'

export default function DownArrow() {
	return (
		<div className='down-arrow animate' onClick={() => scrollAnimation('#sponsors')}>
			<i class="large material-icons">keyboard_arrow_down</i>
		</div>
	)
}
