import React from 'react'
import './Homepage.scss'
import scrollAnimation from '../../HelperFunctions/ScrollAnimation'
import DownArrow from '../../utilities/DownArrow/DownArrow'

export default function Homepage() {
	return (
		<div id='homepage'>
			<div className='title-wrapper'>
				<h1>Kunstrasen für Walschleben.</h1>
				<div> <a onClick={() => scrollAnimation('#sponsors')}>Zu den Sponsoren</a></div>
				<DownArrow />
			</div>
		</div>
	)
}