import './Navbar.scss';

export default function Navbar() {

	return (
		<nav>
			<div className="nav-wrapper">
				<a href="#" className="logo black-text left">FC Walschleben</a>
				<ul id="nav-mobile" className="right">
					<li><a href="#support" className='black-text'>Unterstützer werden</a></li>
					<li><a href="#about" className='black-text'>About</a></li>
				</ul>
			</div>
		</nav>
	)
}