import Navbar from './utilities/Navbar/Navbar'
import Homepage from './pages/Homepage/Homepage'
import Sponsoren from './pages/Sponsorpage/Sponsorpage'
// import Support from './pages/Supportpage/Supportpage'
// import About from './pages/Aboutpage/Aboutpage'
import Footer from './utilities/Footer/Footer'
import './App.scss'

function App() {
  return (
    <div className="App">
      <Navbar />
      <Homepage />
      <Sponsoren />
      {/* <Support />
      <About /> */}
      <Footer />
    </div>
  );
}

export default App;
