import React from 'react'
import Footballfield from '../../utilities/SponsorShowoff/Footballfield'
import './Sponsorpage.scss'

export default function FootballField() {
	return (
		<>
			<div id="sponsors">
				<h2>Unsere Sponsoren</h2>
				<Footballfield />
			</div>
		</>
	)
}