export default class Tile {
	constructor(x1, y1, x2, y2) {
		this.x1 = x1
		this.y1 = y1
		this.x2 = x2
		this.y2 = y2
		this.width = x2 - x1
		this.height = y2 - y1
		this.size = this.width * this.height
	}

	static getRandomNumberNearPointFive() {
		// averaging a few math.random() calls
		const n = 8
		let total = 0
		for (let i = 0; i < n; i ++)
			total += Math.random()
		return total / n
	}

	halve(desiredWidthHeightRatio) {
		if (this.height > this.width * desiredWidthHeightRatio) {
			// cut horizontally
			const newBorderY = this.y1 + Tile.getRandomNumberNearPointFive() * this.height;
			const firstNewTile = new Tile(this.x1, this.y1, this.x2, newBorderY)
			const secondNewTile = new Tile(this.x1, newBorderY, this.x2, this.y2)
			return [firstNewTile, secondNewTile]
		} else {
			// cut vertically
			const newBorderX = this.x1 + Tile.getRandomNumberNearPointFive() * this.width;
			const firstNewTile = new Tile(this.x1, this.y1, newBorderX, this.y2)
			const secondNewTile = new Tile(newBorderX, this.y1, this.x2, this.y2)
			return [firstNewTile, secondNewTile]
		}

	}

	static getTiling(numberOfTiles, desiredWidthHeightRatio) {
		let allTiles = [new Tile(0, 0, 100, 100)];
		for (let tile = 1; tile < numberOfTiles; tile++){
			// cut the biggest tile in half
			const firstTile = allTiles.shift()
			const [newTile1, newTile2] = firstTile.halve(desiredWidthHeightRatio)
			allTiles.push(newTile1)
			allTiles.push(newTile2)
			// sort to always halve the biggest tile
			allTiles.sort((a, b) => b.size - a.size);
		}
		return allTiles;
	}
}