import React, { useEffect, useState } from 'react'
import './SponsorShowoff.scss'
import './LawnPiece'
import LawnPiece from './LawnPiece'
import Tile from './Tile'

const companies = ["Apple", "Microsoft", "Der Fleischer von nebenan", "Klempner Walschleben", "lol", "Stadtwerke"]
const getRandomCompany = () => companies[Math.floor(Math.random() * companies.length)]

export default function SponsorShowoff() {
	const [allDivs, setAllDivs] = useState([])

	useEffect(() => {
		computeTiling(setAllDivs)
	}, [])



	return (
		<div className='sponsor-showoff'>
			{allDivs}
		</div>
	)
}


const computeTiling = (setAllDivs) => {
	const containerOnPage = document.querySelector('.sponsor-showoff')
	if (!containerOnPage) return;

	const allSponsors = []
	const numberOfSponsors = 15;
	for (let i = 0; i < numberOfSponsors; i++)
		allSponsors.push(<LawnPiece key={i} company={getRandomCompany()} />)

	const divWidthHeightRatio = (containerOnPage.clientWidth) / containerOnPage.clientHeight

	const allTiles = Tile.getTiling(numberOfSponsors, 1 / divWidthHeightRatio)
	const allDivs = []
	allTiles.forEach((tile, i) => {
		const style = {
			left: `${tile.x1}%`,
			top: `${tile.y1}%`,
			width: `${tile.width}%`,
			height: `${tile.height}%`,
		}
		allDivs.push(<div key={i} className='sponsor-tile' style={style}>
			<LawnPiece company={getRandomCompany()} />
		</div>)
	})
	setAllDivs(allDivs)
}