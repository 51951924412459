import React from 'react'
import './Footballfield.scss'
import SponsorShowoff from './SponsorShowoff'

export default function Fussballfeld() {
	return (
		<div id='football-field'>
			<SponsorShowoff/>
			<div className='field-side'>
				<div className='goal-area normal-marking remove-border'>
					<div className='penalty-area normal-marking'></div>
				</div>
				<div className='penalty-circle circle normal-marking'>
					<div className='penalty-point circle'></div>
				</div>
			</div>
			<div className='middle-line'></div>
			<div className='field-side mirrored'>
				<div className='goal-area normal-marking remove-border'>
					<div className='penalty-area normal-marking'></div>
				</div>
				<div className='penalty-circle circle normal-marking'>
					<div className='penalty-point circle'></div>
				</div>
				<div className='center-circle circle normal-marking'>
					<div className='center-point circle'></div>
				</div>
			</div>
			<div className='corner-marking top left'></div>
			<div className='corner-marking top right'></div>
			<div className='corner-marking bottom left'></div>
			<div className='corner-marking bottom right'></div>
		</div>
	)
}
