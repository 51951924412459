import React, { useState } from 'react'
import ReactCardFlip from 'react-card-flip'
import './LawnPiece.scss'

export default function LawnPiece(props) {

	const [isFlipped, setIsFlipped] = useState(false)
	const handleClick = () => setIsFlipped(!isFlipped)

	return (
		<div className='lawn-piece' >
			<ReactCardFlip isFlipped={isFlipped} flipDirection='horizontal'>
				<div onClick={handleClick} className='lawn-piece-front' >
					{/* <span className='company-name'> {props.company}</span> */}
					<img src={`/pictures/${props.company.toLowerCase()}-logo.png`} alt={props.company}/>
				</div>
				<div onClick={handleClick} className='lawn-piece-back' >
					<span className='company-name'> {props.company}</span>
					<br/>
					<span className='company-description'> A super creative description.</span>
				</div>
			</ReactCardFlip>
		</div>
	)
}
