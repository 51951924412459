import React from 'react'
import './Footer.scss'

export default function Footer() {
	return (
		<footer className="page-footer">
			<div className="footer-copyright">
				<div className="container">
					© 2021
				</div>
			</div>
		</footer>
	)
}
